.app__navbar {
    width: 100%;
  
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 1.2cm;
    padding: 1rem 2rem;
    //background: rgba(177, 243, 207, 0.25);
    //box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(4px);
    //border: 1px solid rgba(71, 184, 19, 0.18);
    position: fixed;
    z-index: 2;
  
  }
  
  .app__navbar-logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: rgb(83, 83, 231);
    font-family: Bahnschrift SemiBold;
    margin-left: 7%;
    font-size: x-large;
    margin-top: 6px;
    cursor: pointer;
    /*img {
      width: 90px;
      height: 20px;
  
      @media screen and (min-width: 2000px) {
        width: 180px;
        height: 40px;
      }
    } */
  }
  
  .app__navbar-title{
      position: fixed;
      font-family:Cambria, Cochin, Lucida, monospace, Georgia, Times, 'Times New Roman', serif;
      text{
        animation-name: eg;
        //color: rgb(218, 123, 34);
        animation-duration: 7s;
        animation-iteration-count: infinite;
        
        
      }
      @keyframes eg{
        50.00% { color: rgb(83, 83, 231);}
        60.29% { color: rgb(109, 211, 88); }
        80.57% { color: violet;  }
        50.86% { color: green;  }
        40.43% { color: indigo; }
        70.71% { color: rgb(30, 228, 168);}
        100.00% { color: rgb(218, 123, 34); }
      }
      @media screen and (max-width: 500px) {
        text{
        margin-left: -15%;
        }
      }
      
      
    }
    
  
  .app__navbar-links {
    flex: 1;
    display: flex;
    justify-content: center;
    
    align-items: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    list-style: none;
    
  
    li {
      margin: 0 2rem;
      cursor: pointer;
  
      flex-direction: column;
  
      div {
        width: 5px;
        height: 5px;
        background: transparent;
        border-radius: 50%;
  
        //margin-bottom: 5px;
        
      }
  
      a {
        color: rgb(218, 123, 34);
        text-decoration: none;
        flex-direction: column;
        
        text-transform: uppercase;
        font-weight: 700;
  
        transition: all 0.3s ease-in-out;
  
        &:hover {
          color: var(--secondary-color);
        }
      }
  
      &:hover {
        div {
          background: var(--secondary-color);
        }
      }
    }
  
    @media screen and (max-width: 900px) {
      display: none;
    }
  }
  
  .app__navbar-menu {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--secondary-color);
    
    svg {
      width: 70%;
      height: 70%;
      color: var(--white-color);
      cursor: pointer;
      

    }
  
    div {
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 5;
  
      padding: 1rem;
  
      width: 80%;
      height: 100vh;
      
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      flex-direction: column;
      
      background: url('../../assets/bgWhite.png');
    
      background-color: var(--white-color);
      background-size: cover;
      
      background-repeat: repeat;
  
      /* top box shadow */
      box-shadow: 0px 0px 20px rgba(168, 168, 168, 0.15);
      
      svg {
        width: 35px;
        height: 35px;
        color: var(--secondary-color);
        margin: 0.5rem 1rem;
        cursor: pointer;
        //background: rgba(177, 243, 207, 0.25);

      }
  
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        height: 100%;
        width: 100%;
  
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
  
        li {
          margin: 1rem;
  
          a {
            color: var(--gray-color);
            text-decoration: none;
            font-size: 1rem;
            text-transform: uppercase;
            font-weight: 500;
  
            transition: all 0.3s ease-in-out;
  
            &:hover {
              color: var(--secondary-color);
            }
          }
        }
      }
  
      @media screen and (min-width: 900px) {
        display: none;
        
      }
    }
  
    @media screen and (min-width: 900px) {
      display: none;
    }
  }